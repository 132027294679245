<template>
    <div class="content">
        <div>
            <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
        </div>

        <el-table :data='$store.state.CompanyDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true' size="mini">
            <el-table-column align="center" prop="cp_name" label="公司名称"></el-table-column>
            <el-table-column align="center" prop="cp_person" label="联系人"></el-table-column>
            <el-table-column align="center" prop="cp_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="cp_addr" label="公司地址"></el-table-column>
            <el-table-column align="center" prop="cp_remark" label="备注"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                    <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog v-if="companyDialog" :title="title" :visible.sync="companyDialog" width='40%' :append-to-body='true'>
            <el-form :model="companyForm" :rules="rules" ref="companyForm" label-width="100px" size='medium'>
                <el-form-item label="公司名称" prop="cp_name">
                     <el-input v-model="companyForm.cp_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人" >
                    <el-input v-model="companyForm.cp_person"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="companyForm.cp_phone"></el-input>
                </el-form-item>
                <el-form-item label="公司地址">
                    <el-input v-model="companyForm.cp_addr"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="companyForm.cp_remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="companyDialog = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {post,get} from '../../util/util'

export default{
    data(){
        return{
            companyDialog:false,
            title:'',
            companyForm:{
                cp_id:0,
                cp_name:'',
                cp_person:'',
                cp_phone:'',
                cp_addr:'',
                cp_remark:''
            },
            rules: {
                cp_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
            },
        }
    },
    created(){

    },
    mounted(){
        if(this.$store.state.CompanyDataList.length===0){
            this.$store.dispatch('getCompanyDataList')
        }
    },
    methods:{
        Add(){
            this.companyDialog=true
            this.title='新增'
            this.companyForm.cp_id=0
            this.companyForm.cp_name=''
            this.companyForm.cp_person=''
            this.companyForm.cp_phone=''
            this.companyForm.cp_addr=''
            this.companyForm.cp_remark=''
        },
        Edit(row){
            this.companyForm= JSON.parse(JSON.stringify(row))
            this.companyDialog=true
            this.title='修改'
        },
        Del(row){
            this.$confirm('确定删除公司“' + row.cp_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                get('company/del?id=' + row.cp_id+'&name='+row.cp_name).then(res => {
                    if (res.rpStatus === 10000) { this.$store.dispatch('getCompanyDataList') } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            this.$refs.companyForm.validate((valid) => {
                if (valid) {
                    post('company/edit', this.companyForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.$store.dispatch('getCompanyDataList')
                            this.companyDialog = false
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        }
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.search{
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.item label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>